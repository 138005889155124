import { call, put, select, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import { IRequestObject } from '../../../../../boilerplate/redux/types/IRequestObject';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import RouteMetadataService from '../../../../services/RouteMetadataService';
import GeolocationService from '../../../Base/services/GeolocationService';
import { IRouteMetadataType } from '../../../Base/types/IRouteMetadataType';
import {
	addLeafletEntries,
	addLeafletEntry, LEAFLET_FETCH_ENTRIES,
	LEAFLET_FETCH_ENTRY, setLeafletErrors,
} from './leafletActions';

/**
 * Fetch entry list
 */
function* fetchLeaflets(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	// add geolocation params
	let theUrl =  '/api/public/v1/leaflets/';
	let params = action.params;

	if (action.params.onlyNearbyLeaflets) {
		const geolocationReducer = yield select((state: any) => state.geolocationReducer);
		params = GeolocationService.addGeolocationParams(geolocationReducer.currentLocation, action.params);

		theUrl =  '/api/public/v1/leaflets/nearby';
	}

	// if we have an order
	if (params.orderBy && (params.orderBy === 'createdAt' || params.orderBy === 'rank')) { // TODO: fix this to make it usable for all of them
		params.sort = params.orderBy + ','+ ((params.orderDir) ? params.orderDir : 'desc');
	}

	// add default params
	params.include_ended = false;
	params.include_publish_to_all =  true;

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest({
				url: theUrl,
				params,
			} as IRequestObject);
		});

		if (action.params.pushRouteMetadata) {
			const totalActiveElements = response.getContent('totalActiveElements');

			RouteMetadataService.setMetadata(action.queryKey, {
				totalItemsCount: typeof totalActiveElements !== "undefined" ? totalActiveElements : response.getContent('totalElements'),
				fullItemsCount: response.getContent('totalElements'),
			} as IRouteMetadataType);
		}

		yield put(addLeafletEntries(
			action.queryKey,
			response.getContent('content'),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setLeafletErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry list
 */
function* fetchLeaflet(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const { params } = action;

	let url = `/api/public/v1/leaflets/${params.slug}`;

	if (params.vendorSlug != null && params.stateSlug != null) {
		delete params.slug;
		url = `/api/public/v1/rwv/leaflets/${params.vendorSlug}/${params.stateSlug}`;
	}

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: url,
					params,
				} as IRequestObject
			);
		});

		yield put(addLeafletEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setLeafletErrors(action.queryKey, e));
	}
}

export function* leafletSaga() {
	yield takeEvery(LEAFLET_FETCH_ENTRY, fetchLeaflet);
	yield takeEvery(LEAFLET_FETCH_ENTRIES, fetchLeaflets);
}
