import * as React from 'react';
import styles from '../CarouselTeaser.scss';
import { ICarouselTeaser } from '../types/CarouselTeaser/ICarouselTeaser';
import { IQueryMetaDataType } from '../../../../boilerplate/redux/types/IQueryMetaDataType';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CarouselTeaserArrow from './CarouselTeaserArrow';
import ImageEnhanced from '../../../components/General/ImageEnhanced/ImageEnhanced';
import CarouselTeaserIndicator from './CarouselTeaserIndicator';
import InternalTrackingService from '../../../services/InternalTrackingService';
import DataLayerService from '../../Base/services/DataLayerService';
import { isMobile } from '../../../utils/responsiveUtils';

interface ICarouselTeaserListingPropsType {
    queryKey?: string;
    teasers: ICarouselTeaser[];
    metaData?: IQueryMetaDataType;
}

const arrowNext = (clickHandler, hasNext, label) => <CarouselTeaserArrow clickHandler={clickHandler} hasNext={hasNext} label={label} direction="right" />;
const arrowPrev = (clickHandler, hasNext, label) => <CarouselTeaserArrow clickHandler={clickHandler} hasNext={hasNext} label={label} direction="left" />;
const indicator = (clickHandler, isSelected, index) => <CarouselTeaserIndicator clickHandler={clickHandler} isSelected={isSelected} index={index} />;

class CarouselTeaserListing extends React.PureComponent<ICarouselTeaserListingPropsType> {

    componentDidMount() {
        this.trackCarouselImpression(0);
    }

    public render() {
        const { teasers } = this.props;

        return (
            <Carousel
                className={styles.carouselTeaser}
                emulateTouch
                axis="horizontal"
                showThumbs={false}
                dynamicHeight={false}
                swipeable
                autoPlay
                autoFocus
                infiniteLoop
                showArrows
                showStatus={false}
                interval={4000}
                renderArrowNext={arrowNext}
                renderArrowPrev={arrowPrev}
                renderIndicator={indicator}
                onChange={this.trackCarouselImpression}
            >
                {teasers.map((teaser, index) => {
                    const isAktionsfinderLink = teaser.targetUrl.includes('aktionsfinder.at');
                    const target = isAktionsfinderLink ? '_self' : '_blank';

                    return (
                        <div key={index}>
                            <a href={teaser.targetUrl} target={target} className={styles.teaserWrapper} onClick={() => this.trackTeaserClick(teaser)}>
                                <ImageEnhanced source={teaser.resolutions} size={isMobile() ? 'l' : 'lx'} fallbackSize='l'/>
                            </a>      
                        </div>
                    );
                })}
            </Carousel>
        );
    }


    private trackedTeasers: Map<string, boolean> = new Map();
    
    /**
     * Track carousel impression
     */
    private trackCarouselImpression = (index: number) => {
        const { teasers } = this.props;
        const teaser = teasers[index];

        if (teaser?.id && !this.trackedTeasers.has(teaser?.id)) {

            InternalTrackingService.batchTrack(`carousel-impression-${teaser.id}`, 'CAROUSEL_IMPRESSION', {
                'teaser_id': teaser.id ?? '',
                'vendorId': teaser.vendor?.id ?? '',
                'targetURL': teaser.targetUrl ?? '',
            });

            this.trackedTeasers.set(teaser.id, true);
        }
    }

    /**
     * Track click out
     */
    private trackTeaserClick = (teaser: ICarouselTeaser) => {
        InternalTrackingService.track('CAROUSEL_CLICK', {
            adId: teaser.id,
            targetURL: teaser.targetUrl ?? '',
        });

        DataLayerService.push('CAROUSEL_CLICK', {
            adId: teaser.id,
        });
    };
}

export default CarouselTeaserListing;
