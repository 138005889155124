import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addVendorWidgetEntries,
	setVendorWidgetErrors,
	VENDOR_WIDGET_FETCH_ENTRIES,
} from './vendorWidgetActions';

/**
 * Fetch entry list
 */
function* fetchVendorWidgets(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = afCoreRequest.mergeParams({
		page: 0,
		size: 5,
	}, action.params);

	let url = '/api/public/v1/widgets/vendors';
	let contentKey = 'content';
	if (params.brand) {
		url = `/api/public/v1/vendors/brand/${params.brand}`;
		params.brand = null;
		contentKey = null;
	}

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url,
					params,
				} as IRequestObject);
			},
		);

		yield put(
			addVendorWidgetEntries(
				action.queryKey,
				response.getContent(contentKey),
				response.getMetaData(),
			),
		);
	} catch (e) {
		yield put(setVendorWidgetErrors(action.queryKey, e));
	}
}

export function* vendorWidgetSaga() {
	yield takeEvery(VENDOR_WIDGET_FETCH_ENTRIES, fetchVendorWidgets);
}
